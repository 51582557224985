<template>
  <div>
    <button type="submit" class="btn btn-primary" id="submitBtn" v-text="message">
    </button>
  </div>
</template>

<script>
export default {
  props: ['message'],
  mounted() {
    var url_string = window.location.href;
    if (url_string.includes("success")) {
      if ($('.invalid-feedback').length == 0) {
        this.$bvToast.toast('Berhasil :)', {
          title: `${this.message} status`,
          variant: 'success',
          autoHideDelay: 5000,
          solid: true
        })
        this.$toaster.success('Berhasil :)');
      }
      else {
        this.$bvToast.toast('Gagal :(', {
          title: `${this.message} status`,
          variant: 'error',
          autoHideDelay: 5000,
          solid: true
        })
      }
    }
  }
}
</script>

