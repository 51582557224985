<template>
  <div>
    <b-form-datepicker 
      :id="name" 
      v-model="dvalue" 
      :date-disabled-fn="dateDisabled"
      show-decade-nav
      :disabled="disableDatePicker" 
      :show-current="showDate"
      class="mb-2"
    >
    </b-form-datepicker>
    <input  
        :id="name"
        type="hidden" 
        class="form-control @error('test_date') is-invalid @enderror" 
        :name="dname" 
        :value="dvalue" 
        autofocus>
  </div>
</template>

<script>
  export default {
    props: ['name', 'value', 'isTest', 'isDisable'],
    mounted(){
      // console.log(this.isDisable);
      // console.log(this.isTest);
    },
    data() {
      return {
        dvalue: this.value,
        dname: this.name,
        disableDatePicker: (this.isDisable != ''),
      }
    },
    methods: {
      showDate() {
        if (this.isTest == "false") return new Date();
        const now = new Date();
        const nowMonth = now.getMonth();
        const nowDay = now.getDay();
        if (nowMonth == 0 || (nowMonth == 1 && nowDay <= 11)) {
          return "2022-02";
        }
        else if (nowMonth == 1 || (nowMonth == 2 && nowDay <= 11)) {
          return "2022-03";
        }
        else if (nowMonth == 2 || (nowMonth == 3 && nowDay <= 8)) {
          return "2022-04";
        }
        else if (nowMonth == 3 || (nowMonth == 4 && nowDay <= 6)) {
          return "2022-05";
        }
      },
      dateDisabled(ymd, date) {
        if (this.isTest == "false") return false;
        // Disable weekends (Sunday = `0`, Saturday = `6`) and
        // disable days that fall on the 13th of the month
        const weekday = date.getDay();
        if (weekday === 0) return true;
        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getYear();
        const now = new Date();
        const nowMonth = now.getMonth();
        const nowDay = now.getDay();
        let wave, startDate, endDate, testMonth;
        if (nowMonth == 0 || (nowMonth == 1 && nowDay <= 11)) {
          wave = "Gelombang II";
          startDate = 14;
          endDate = 24;
          testMonth = 1;
        }
        else if (nowMonth == 1 || (nowMonth == 2 && nowDay <= 11)) {
          wave = "Gelombang III";
          startDate = 14;
          endDate = 24;
          testMonth = 2;
        }
        else if (nowMonth == 2 || (nowMonth == 3 && nowDay <= 8)) {
          wave = "Gelombang IV";
          startDate = 11;
          endDate = 21;
          testMonth = 3;
        }
        else if (nowMonth == 3 || (nowMonth == 4 && nowDay <= 6)) {
          wave = "Gelombang V";
          startDate = 9;
          endDate = 19;
          testMonth = 4;
        }

        if (day >= startDate && day <= endDate && month == testMonth) return false
        else return true;
      }
    }
  }
</script>