<template>
  <div id="liveToaster">
  </div>
</template>

<script>
export default {
  props: ['status', 'isFilled', 'isPaid'],
  mounted() {
    if (this.status == "Data") {
      (this.isFilled)  ?
        this.makeToast('info', 'Data diterima, silahkan menunggu untuk validasi'):
        this.makeToast('danger', 'Data belum lengkap');
    }
    else if (this.status == "Pembayaran") {
      (this.isPaid)  ?
        this.makeToast('info', 'Terima kasih, silahkan menunggu untuk validasi'):
        this.makeToast('danger', 'Belum bayar');
    }
    else if (this.status == "Tes") {
      this.makeToast('warning', 'Belum menjalani tes');
    }
    else if (this.status == "Lulus") {
      this.makeToast('success', 'Sudah lulus');
    }
  },
  methods: {
      makeToast(variant = null, message = null) {
        this.$bvToast.toast(message, {
          title: `Status: ${this.status || ''}`,
          variant: variant,
          autoHideDelay: 1000000,
          solid: true
        })
      }
    }
}
</script>

